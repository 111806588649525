// Translated
// Migrated
<template lang="pug">
NuxtLayout(name="banner-default")
  .startpage-rolfs.pb-5
    AlgoliaSearchBar.px-0.mt-n5(
      :autofocus="false"
      size="lg"
    )

    .row.px-0
      .col-12
        ReviewScroller(:reviews="reviews")

      .col-12(v-if="showCustomCampaign")
        LazyRolfsStartpageCustomCampaign

      HydrateWhenVisible.min-h-px-980.min-h-px-md-950
        LazyHomePitch

      HydrateWhenVisible.h-100.w-100.min-h-px-1200.min-h-px-md-400
        LazyHomeTripList

      HydrateWhenVisible.min-h-px-130.min-h-px-md-80.w-100
        LazyHomeCategories

      template(v-if="campaignList.length > 0")
        .col-12.mb-4.pb-2.text-left.text-md-center
          h3.mb-3.startpage-ongoing-campaigns(data-i18n="activeCampaigns") {{ $t('activeCampaigns') }}
          p(data-i18n="activeCampaignsText") {{ $t('activeCampaignsText') }}

        .col-12
          HydrateWhenVisible.min-h-px-350.min-h-px-md-450
            LazyHomeCampaigns(:campaigns="campaigns")

      .col-12.mb-4(v-if="hasBlog")
        HydrateWhenVisible.min-h-px-650.min-h-px-md-600
          LazyHomeBlogList

      .col-12.mb-4
        HydrateWhenVisible.min-h-px-900.min-h-px-md-800
          LazyHomeCalendar
</template>

<script setup>
import { LOCALES_WITH_STARTPAGE_CUSTOM_CAMPAIGN, LOCALES_WITH_BLOG } from '@layers/web/constants/locale.js'

const rootStore = useRootStore()
const localeStore = useLocaleStore()
const blogStore = useBlogStore()

const tripStore = useTripStore()

const { locale } = storeToRefs(localeStore)
const campaignsStore = useCampaignsStore()

const reviewsStore = useReviewsStore()
const { reviews } = storeToRefs(reviewsStore)

const { t } = useI18n()
const { urls: localeURLs } = useLocale()

const showCustomCampaign = computed(() => {
  return LOCALES_WITH_STARTPAGE_CUSTOM_CAMPAIGN.includes(locale.value)
})

const { data } = await useAsyncData(async () => {
  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['homeDescription', 'siteTitle', 'siteTitleBrand']))

  const [categoryStartTrips, campaigns] = await Promise.all([
    tripStore.fetchCategoryStart(),
    campaignsStore.fetchPromoted(),
    blogStore.fetchBlogList(),
    reviewsStore.fetchAll(),
    rootStore.fetchStartCalendar(),
    rootStore.fetchStartCategories(),
  ])

  const firstTrip = categoryStartTrips?.[0]
  if (!firstTrip) {
    return
  }

  const pageBanner = {
    title: firstTrip.title,
    subtitle: firstTrip.subtitle,
    special: {
      type: 'crown',
    },

    link: {
      text: t('readMore'),
      href: firstTrip.url,
    },
  }

  // const isVideo = firstTrip.main_movie
  const isVideo = false
  if (isVideo) {
    pageBanner.background = {
      video: {
        src: firstTrip.main_movie,
        type: firstTrip.main_movie_type,
        poster: firstTrip.main_picture_cloudinary,
      },
    }
  } else {
    pageBanner.background = {
      image: {
        url: firstTrip?.main_picture_cloudinary,
        type: 'cloudinary',
      },
    }
  }

  rootStore.SET_PAGE_BANNER(pageBanner)

  return {
    firstTrip,
    campaigns,
  }
})
const firstTrip = data.value?.campaigns
const campaigns = data.value?.campaigns

useHead({
  title: () => `${t('siteTitle')}`,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () => t('homeDescription'),
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${t('siteTitle')} - ${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: () => formatCloudinaryImagePath(firstTrip?.main_picture_cloudinary, {
        forceJPG: true,
        isSol: false,
      }),
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: () => t('homeDescription'),
    },
    {
      name: 'google-site-verification',
      content: 'vCKb9vRFS3-tspc-TOjttHLY7S0A9wNQbWEXBLFm08E',
    },
  ],
})

const campaignList = computed(() => {
  return [ ...(campaigns || []) ]
    .filter(Boolean)
    .filter(campaign => campaign?.sitemap === 2)
})

const hasBlog = computed(() => LOCALES_WITH_BLOG.includes(locale.value))
</script>

<style lang="scss">
.startpage-rolfs {
  .startpage-campaign-slide {
    height: 450px;

    @media (max-width: $md) {
      height: 350px;
    }
  }

  &.startpage-category-section {
    padding-top: 70px;
    margin-top: 0;
    position: relative;
    background: $lightest-blue;

    @media (max-width: $md) {
      padding-top: 1rem;
    }
  }

  .startpage-ongoing-campaigns {
    font-family: $serif-font;
  }

  .trip {
    &-item {
      .trip {
        &-description {
          color: white;
          background: transparent;
        }

        &-image {
          height: 400px;
        }
      }

      &.startpage-category-item {
        height: 160px;
        min-height: 160px;
      }
    }

    &-description {
      text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.57);
      font-weight: 500;
    }
  }

  .startpage-category-slide {
    height: 270px !important;
  }

  &_info-grouptravel {
    button {
      $x-padding: 2.2rem;
      padding-left: $x-padding;
      padding-right: $x-padding;

      h4 {
        font-size: 1.3rem;
      }
    }

    &:hover {
      button {
        background: $orange !important;
      }
    }
  }
}

.articles-content {
  &-category {
    padding-top: $main-banner-height - 120;

    @media (max-width: $lg) and (min-width: $md) {
      padding-top: $main-banner-height - 130 !important;
    }

    @media (max-width: $md) and (min-width: $sm) {
      padding-top: $main-banner-height - 80 !important;
    }

    @media (max-width: ($mobile - 1px)) {
      padding-top: 0;
    }
  }

  &-search {
    height: 121px;

    input {
      height: 121px;
      padding-left: 48px;

      @media (max-width: ($mobile - 1px)) {
        height: 60px;
        font-size: 16px;
        padding-left: 20px;
      }
    }

    &-btn {
      width: 196px;

      @media (max-width: ($mobile - 1px)) {
        width: 80px;
      }
    }
  }
}
</style>
