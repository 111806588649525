import { default as _91slug_937j6XNybsvFMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_930UTMvsRkweMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93QuhTMpfSMNMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_93nvOElMhAQwMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue?macro=true";
import { default as _91id_93H9tb125I5hMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93O49fMOLHfWMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93pp0aNw6n5qMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_936PKvBLLZAsMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93rWCot2gLOGMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93VVsxYSTFsrMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93xzGRSENgxnMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_9318C2cmmBdnMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93El5FyToBo8Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93grPV66RE3DMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_935N2KiKxDbTMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93ECL7Tq2pfvMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93nKCfNz3k7SMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_931ZNo1KWEjBMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93iLqUjmaNO6Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93avSXJWz2iAMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93zsPI7cMeAbMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93QkYXAXGjZSMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexWEhGfFw2cLMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callback2VAljGkHoHMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweekZ55ZWn4vbZMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93TPVkX30MrDMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as index9dgOUB3X6zMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as byebyeieUPZFGva4uPMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as index5oZy6dwEWeMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminute45CnVWmRP4Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93RYLsShx7wyMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as index7h0ORUMjBoMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as cataloguekV4BoLHw38Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintdUUOsAkBezMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuelld5r7GR52nHMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93jq6ZoAEtLEMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexUykaUeMvrkMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqETYjvQVpUMMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93mnqSnKSAokMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexQOxdWA9VPtMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93FOYWYSxcMIMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexTq8kLfmMZFMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexvYTSXHW2R3Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptraveltL8ecB7at1Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexgAUTRH7x7JMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteicgLIWWmNrMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as mapAklnetucfEMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokning47kXVj9mH2Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterRzHSHgEf5oMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineJA3o2e5j25Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failed9detD9dlkSMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexW3eCWVVQk7Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchAxeeVJM0XOMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexcaXcNz1wgYMeta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneoTYHTymjC6Meta } from "/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/guider/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagsprogram",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/skib",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-tripid",
    path: "/tilfredshed-status/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/din-rejse",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/foredrag",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/forbindelser",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/konkurrence",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/bestil/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93QkYXAXGjZSMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/bestil/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: index5oZy6dwEWeMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/afbudsrejser",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampagne/:name()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampagne",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: cataloguekV4BoLHw38Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_935N2KiKxDbTMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/konkurrence/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintdUUOsAkBezMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93El5FyToBo8Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/gavekort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/gavekort",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hoteller/:name()",
    meta: _91name_93FOYWYSxcMIMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hoteller",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93xzGRSENgxnMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/forbindelser/:tab()",
    meta: _91tab_93rWCot2gLOGMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/grupperejse",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/foredrag/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/afbudsrejser",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/kort",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/din-rejse/:tab()",
    meta: _91tab_93pp0aNw6n5qMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93O49fMOLHfWMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/anmeldelse",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-tripid-paxid",
    path: "/tilfredshed-status/:tripid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/søg",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/skib/:name()",
    meta: _91name_93QuhTMpfSMNMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagsprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/guider/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/guider",
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneoTYHTymjC6Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-26-1145/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]