// Migrated
<template lang="pug">
.crown-container.d-flex.flex-column.justify-content-center.position-relative(
  :class="`text-${color} ${getSize}`"
)
  i-crown.position-absolute(
    filled
    :font-controlled="false"
    width="100%"
    :class="{ 'crown-image-shadow': shadow }"
  )
  .position-relative.d-flex.justify-content-center.flex-column(
    :class="[{ 'crown-text-shadow': shadow }, size === 'sm' ? 'p-px-30' : 'p-px-40']"
  )
    span.text-center.d-block(:data-i18n="smallTitleKey") {{ $t(smallTitleKey) }}
    h4.text-uppercase.font-weight-semibold.text-center.mt-n1.text-break-pre-line(:data-i18n="mainTitleKey") {{ toUnixNewlines($t(mainTitleKey)) }}
</template>

<script>
export default defineNuxtComponent({
  props: {
    smallTitleKey: {
      type: String,
      default: 'crownSmallTitle',
    },

    mainTitleKey: {
      type: String,
      default: 'crownMainTitle',
    },

    size: {
      type: String,
      default: 'sm',
    },

    shadow: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: 'white',
    },
  },

  computed: {
    getSize () {
      switch (this.size) {
        case 'lg':
          return 'crown-lg'
        default:
          return 'crown-sm'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
$base-font-size-sm: .8rem;
$base-font-size-lg: 1rem;

.crown {
  &-sm {
    span {
      font-size: $base-font-size-sm;
    }
    h4 {
      font-size: $base-font-size-sm * 1.37;
    }

    @media (max-width: $sm) {
      $modifier: .9;

      span {
        font-size: $base-font-size-sm * $modifier;
      }

      h4 {
        font-size: $base-font-size-sm * $modifier * 1.37;
      }
    }
  }

  &-lg {
    span {
      font-size: $base-font-size-lg;
    }
    h4 {
      font-size: $base-font-size-lg * 1.37;
    }
  }

  &-container {
    h4 {
      margin-top: 0 !important;
    }
  }

  &-image-shadow {
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,.35))
  }

  &-text-shadow {
    * {
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,.4));
    }
  }
}
</style>
